
import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';

import Loader from '../images/loader';
import FadeInLink from "./transitions/FadeInLink"
import NewsletterFirework from "./NewsletterFireworks"

import icons from '../images/icons.svg';
const BrainIcon = icons + '#brain';

class Newsletter extends Component {

  constructor(props) {
    super(props)
    this.submitBtn = React.createRef();
  }

  state = {
    status: 'IDLE',
    name: '',
    email: '',
    errorMessage: '',
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    // Prevent default
    event.preventDefault();
    // Blur submit btn
    this.submitBtn.current.blur();
    // Set form state to sending
    this.setState({ status: 'SENDING' });
    // Data to send
    const { name, email } = this.state;
    // Send form
    axios.post('/api/newsletter', {name, email }, {})
    .then((response) => {
      // Success
      setTimeout( () => {
        this.setState({ status: 'SUCCESS' });
      }, 500);
    })
    .catch((error) => {
      // Form submit encountered an error
      if(error.response && error.response.data) {
        if(typeof error.response.data === 'string') {
          this.setState({ errorMessage: error.response.data });
        } else {
          if(typeof error.response.data === 'object') {
            if(typeof error.response.data.title === 'string') {
              this.setState({ errorMessage: error.response.data.title });
            } else {
              this.setState({ errorMessage: 'An error occured. Please try again.' });
            }
          } else {
            this.setState({ errorMessage: 'An error occured. Please try again.' });
          }
        }
      } else {
        this.setState({ errorMessage: 'An error occured. Please try again.' });
      }
      setTimeout( () => {
        this.setState({ status: 'ERROR' });
      }, 500);
    })

  };

  render() {
    const { name, email, status, errorMessage } = this.state;
    const { inPage } = this.props;
    const nameInputClass = classNames('Form__input', {
      'Form__input--hasContent': name,
    });
    const emailInputClass = classNames('Form__input', {
      'Form__input--hasContent': email,
    });
    const formClass = classNames('Form Newsletter__form', {
      'Newsletter__form--sending': status === 'SENDING',
      'Newsletter__form--success': status === 'SUCCESS',
      'Newsletter__form--error': status === 'ERROR',
    });
    return (
      <section className="Newsletter bg-lightgray border-top-medium-gray border-after-medium-gray">
        <div className="Newsletter__inner outer-padding-x fadein">
          {!inPage ? (
            <>
              <h3>
                <span>Want to pick my brain?</span>
                <svg className="fill-black">
                  <use xlinkHref={BrainIcon}></use>
                </svg>
              </h3>
              <p className="has-big-font-size">I will be writing a newsletter around subjects such as:<br/> user experience design, freelance life and web development.</p>
            </>
          ) : null}
          {status !== 'SUCCESS' ? (
            <div>
              <form className={formClass} onSubmit={this.handleSubmit}>
                <div className={nameInputClass}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor="name">First name</label>
                  <div className="Form__inputbar"></div>
                </div>
                <div className={emailInputClass}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor="email">Email address</label>
                  <div className="Form__inputbar"></div>
                </div>
                <div className="Form__input">
                  { status !== 'SUCCESS' ? <input type="submit" value={status === 'SENDING' ? 'Please wait...' : 'Sign me up señor!'} ref={this.submitBtn}/> : null }
                  <div className="Form__indicator">
                    <Loader />
                  </div>
                </div>
              </form>
              {status === 'ERROR' ? <div className="Form__response--error"><p>{errorMessage}</p></div> : null}
              <p className="Newsletter__sub">You can always unsubscribe and I will never <FadeInLink to="/privacy" type="default">share your email</FadeInLink> or spam you with nonsense.</p>
            </div>
          ) : (
            <div className="Newsletter__success max-width-big">
              <h2>Thank you!</h2>
              <div className="max-width">
                <p className="has-big-font-size">Please check {email} for a mail to confirm your subscription.</p>
              </div>
              <NewsletterFirework />
            </div>
          )}
        </div>
      </section>
    )
  }

}

Newsletter.defaultProps = {
  inPage: false
}

export default Newsletter