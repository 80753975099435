import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"

import Newsletter from "../components/Newsletter"

const NewsletterPage = () => {
  return (
    <InnerLayout>
      <SEO title="Newsletter" bodyClass="NewsletterPage"/>
      <InnerWrapper>
        <section className="outer-padding-x outer-padding-y">
          <h1 className="fadein">Newsletter</h1>
          <div className="max-width-big">
            <p className="has-big-font-size fadein" style={{transitionDelay: '.1s'}}>I'm planning my personal newsletter, be among the first to receive it! The subjects will be evolving around UX, web development, digital strategy and freelance life. I would be happy to have you on board. &#9786;</p>
          </div>
          <br/>
          <br/>
          <h2 className="fadein" style={{transitionDelay: '.2s'}}>Sign up to the goodies</h2>
          <Newsletter inPage/>
        </section>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default NewsletterPage
