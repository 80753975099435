import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';

const InnerLayout = ({ children, noTopPadding }) => {
  const wrapperClass = classNames('main-wrapper', {
    'main-wrapper--no-padding': noTopPadding,
  });
  return (
    <>
      <div className="loading-overlay bg-lightgray"></div>
      <main className={wrapperClass}>{children}</main>
    </>
  )
}

InnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noTopPadding: PropTypes.bool
}

InnerLayout.defaultProps = {
  noTopPadding: false
}

export default InnerLayout
